import { createSlice } from "@reduxjs/toolkit";

import accountApi from "../api/account";
import { PostModel } from "../types";

const initialState = {
  posts: [],
} as PostState;

const slice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      accountApi.endpoints.getPosts.matchFulfilled,
      (state, { payload }: { payload: PostModel[] }) => {
        return { ...state, posts: payload };
      }
    );
    builder.addMatcher(
      accountApi.endpoints.updatePostStatus.matchFulfilled,
      (state, { payload }: { payload: PostModel[] }) => {
        let postList = state.posts.slice();
        const index = postList.findIndex((c) => c.postId == payload[0].postId);
        if (index > -1) {
          postList[index] = payload[0];
        } else {
          postList.splice(0, 0, ...payload);
        }
        return {
          ...state,
          posts: postList,
        };
      }
    );
  },
});

export const post = slice.reducer;

export interface PostState {
  posts: PostModel[];
}
