import { lazyImport } from "@/utils/lazyImport";

import { RouteConstant } from "./routeConstant";

const { AuthRoutes } = lazyImport(
  () => import("@/features/auth"),
  "AuthRoutes"
);

export const publicRoutes = [
  {
    path: RouteConstant.Auth,
    element: <AuthRoutes />,
  },
];
