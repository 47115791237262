import { useState } from "react";

const useToggle = (showDefault = false) => {
  const [show, setShow] = useState(showDefault);

  const toggle = () => setShow(!show);

  return {
    show,
    toggle,
    setShow,
  };
};

export default useToggle;
