import { combineReducers } from "@reduxjs/toolkit";

import { login } from "@/features/auth";
import { account, post, postComment, chat } from "@/features/profile";
import { api } from "@/services/api";

import { progress, response } from "./common";

const combinedReducers = combineReducers({
  account,
  chat,
  post,
  postComment,
  login,
  progress,
  response,
  [api.reducerPath]: api.reducer,
});

export type RootState = ReturnType<typeof combinedReducers>;

export default combinedReducers;
