import { themeTextColor } from "@/utils/colorConstant";
import {
  UserIcon,
  PencilSquareIcon,
  TrashIcon,
  XCircleIcon,
  ArrowUturnRightIcon,
  ArrowUpRightIcon,
  MapPinIcon,
  MagnifyingGlassIcon,
  AdjustmentsVerticalIcon,
  PlusIcon,
  MinusIcon,
  HandThumbUpIcon,
  ChatBubbleLeftIcon,
  ArrowLeftIcon,
  ArrowRightEndOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";

type IconProps = {
  className?: string;
};

export const UserIc = ({ className }: IconProps) => {
  return <UserIcon className={className} />;
};

export const EditIc = ({ className }: IconProps) => {
  return <PencilSquareIcon className={className} />;
};

export const DeleteIc = ({ className }: IconProps) => {
  return <TrashIcon className={className} />;
};

export const CrossIc = ({ className }: IconProps) => {
  return <XCircleIcon className={className} />;
};

export const RotateIc = ({ className }: IconProps) => {
  return <ArrowUturnRightIcon className={className} />;
};

export const ArrowUpRightIc = ({ className }: IconProps) => {
  return <ArrowUpRightIcon className={className} />;
};

export const MapPinIc = ({ className }: IconProps) => {
  return <MapPinIcon className={className} />;
};

export const SearchIc = ({ className }: IconProps) => {
  return <MagnifyingGlassIcon className={className} />;
};

export const FilterIc = ({ className }: IconProps) => {
  return <AdjustmentsVerticalIcon className={className} />;
};

export const PlusIc = ({ className }: IconProps) => {
  return <PlusIcon className={className} />;
};

export const MinusIc = ({ className }: IconProps) => {
  return <MinusIcon className={className} />;
};

export const ThumbIc = ({ className }: IconProps) => {
  return <HandThumbUpIcon className={className} />;
};

export const CommentIc = ({ className }: IconProps) => {
  return <ChatBubbleLeftIcon className={className} />;
};

export const BackIc = ({ className }: IconProps) => {
  return <ArrowLeftIcon className={className} />;
};

export const LogoutIc = () => {
  return (
    <ArrowRightEndOnRectangleIcon className={`h-8 w-8 ${themeTextColor}`} />
  );
};

export const ChevronDownIc = ({ className }: IconProps) => {
  return <ChevronDownIcon className={className} />;
};

export const ChevronUpIc = ({ className }: IconProps) => {
  return <ChevronUpIcon className={className} />;
};
