import { createSlice } from "@reduxjs/toolkit";
import chatApi from "../api/account";
import { MessageModel, RecipientModel } from "../types";

const slice = createSlice({
  name: "chat",
  initialState: {
    messages: [],
    recipients: [],
  } as ChatState,
  reducers: {
    selectRecipient(
      state,
      { payload }: { payload: RecipientModel | undefined }
    ) {
      state.selectedRecipient = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chatApi.endpoints.getRecipients.matchFulfilled,
      (state, { payload }: { payload: RecipientModel[] }) => {
        return {
          ...state,
          recipients: payload,
          selectedRecipient: undefined,
        };
      }
    );
    builder.addMatcher(chatApi.endpoints.getMessages.matchPending, (state) => {
      return {
        ...state,
        messages: [],
      };
    });
    builder.addMatcher(
      chatApi.endpoints.getMessages.matchFulfilled,
      (state, { payload }: { payload: MessageModel[] }) => {
        return {
          ...state,
          messages: payload,
        };
      }
    );
  },
});

export const { selectRecipient } = slice.actions;

export const chat = slice.reducer;

export interface ChatState {
  recipients: RecipientModel[];
  messages: MessageModel[];
  selectedRecipient?: RecipientModel;
}
