import { useDispatch, useSelector } from "react-redux";
import { useMemo, useEffect, useState } from "react";
import { RootState } from "@/store/reducers";
import { InputField } from "@/components/Form";
import Recipient from "./Recipient";
import { useGetRecipientsMutation } from "../../api/account";
import { MessageList } from "./MessageList";

export const RecipientList = ({ userId }: { userId: number }) => {
  const allRecipients = useSelector(
    (state: RootState) => state.chat.recipients
  );
  const selectedRecipient = useSelector(
    (state: RootState) => state.chat.selectedRecipient
  );
  const [searchBy, setSearchBy] = useState("");
  const [getRecipients] = useGetRecipientsMutation();

  useEffect(() => {
    getRecipients(userId);
  }, [userId]);

  const recipients = useMemo(() => {
    let list = allRecipients;
    if (searchBy) {
      const searchByValue = searchBy?.replace(/ /g, "").toLowerCase();
      if (searchBy) {
        list = list.filter((c) =>
          c.fullName?.replace(/ /g, "").toLowerCase().includes(searchByValue)
        );
      }
    }
    return list;
  }, [allRecipients, searchBy]);

  if (selectedRecipient) {
    return <MessageList userId={userId} />;
  }

  return (
    <div>
      <div>
        <InputField
          placeholder="Search..."
          name="searchBy"
          value={searchBy}
          onChange={(name, value) => setSearchBy(value)}
        />
      </div>
      <div className="mt-10">
        {recipients.map((item) => (
          <Recipient key={item.userId} recipient={item} />
        ))}
      </div>
    </div>
  );
};
