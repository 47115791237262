import { createSlice } from "@reduxjs/toolkit";

import accountApi from "../api/account";
import { ProfileModel } from "../types";

const initialState = {
  profileList: [],
} as AccountState;

const slice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      accountApi.endpoints.getAccountList.matchFulfilled,
      (state, { payload }: { payload: ProfileModel[] }) => {
        return { ...state, profileList: payload };
      }
    );
    builder.addMatcher(
      accountApi.endpoints.blockUnblockAccount.matchFulfilled,
      (state, { payload }: { payload: any }) => {
        let profileList = state.profileList;
        const index = state.profileList.findIndex(
          (c) => c.userId == payload.userId
        );
        if (index > -1) {
          profileList[index] = {
            ...profileList[index],
            isActive: payload.isActive,
          };
        }
        state.profileList = profileList;
      }
    );
  },
});

export const account = slice.reducer;

export interface AccountState {
  profileList: ProfileModel[];
}
