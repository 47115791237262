import { useNavigate } from "react-router-dom";

import { RouteConstant } from "@/routes/routeConstant";

import { Layout, LoginForm } from "../components";
import { baseURL } from "@/services/api";

export const Login = () => {
  const navigate = useNavigate();

  return (
    <Layout title="Log in to your account">
      <LoginForm onSuccess={() => navigate(RouteConstant.Landing)} />
      <div className="flex justify-center text-sm text-blue-400 mt-2">
        <a
          href={`${baseURL}/templates/termsandconditions.html`}
          target="_blank"
          className="italic underline"
        >
          Terms and conditions
        </a>
        &nbsp;and&nbsp;
        <a
          href={`${baseURL}/templates/privacypolicy.html`}
          target="_blank"
          className="italic underline"
        >
          Privacy Policy
        </a>
      </div>
    </Layout>
  );
};
