import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "progress",
  initialState: { isLoading: false } as ResponseState,
  reducers: {
    setResponse: (state, { payload }: { payload: ResponseState }) => {
      return payload;
    },
  },
});

export const { setResponse } = slice.actions;

export default slice.reducer;

export type ResponseState = {
  success?: boolean;
  message?: string;
  status?: number;
};
