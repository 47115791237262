import { Button } from "../Button";

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
};
export const DialogFooter = ({
  onClose,
  children,
}: {
  onClose: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
      <Button
        className="text-red-500 background-transparent font-bold uppercase"
        type="button"
        size="sm"
        onClick={onClose}
      >
        Close
      </Button>
      {children}
    </div>
  );
};

export const Dialog = ({ isOpen, onClose, children, title }: DialogProps) => {
  return (
    <>
      {isOpen && (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-auto mx-auto max-w-7xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-3xl font=semibold">{title}</h3>
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={onClose}
                  >
                    <div className="text-black  opacity-7 h-6 w-6 flex bg-gray-400 py-0 rounded-full justify-center">
                      x
                    </div>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">{children}</div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};
