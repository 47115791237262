import { Image } from "../Elements";

export const ProfileImg = ({ src }: { src?: string }) => {
  return (
    <Image
      src={src}
      className="w-10 h-10 rounded-full border-gray-500 border"
      isApiUrl
    />
  );
};
