import { FileModel } from "@/types/common";
import { Video } from "./Video";
import { Image } from "./Image";

const UserImage = ({ file }: { file: FileModel }) => {
  return (
    <>
      <div className="m-5 h-[100px] w-1/3">
        {file.isVideo ? (
          <Video apiSrc={file.fileUrl} className="w-full h-full" />
        ) : (
          <Image src={file.fileUrl} className="w-full h-full" isApiUrl />
        )}
      </div>
    </>
  );
};

export const ImageList = ({ files }: { files: FileModel[] }) => {
  return (
    <>
      {files && files.length > 0 && (
        <div className="mt-2 flex justify-between flex-wrap">
          {files.map((file: FileModel, index: number) => (
            <UserImage key={`f${index}`} file={file} />
          ))}
        </div>
      )}
    </>
  );
};
