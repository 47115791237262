import { ReactNode } from "react";
import { BgKey } from "@/types/common";
import {
  gradientBgPGray,
  gradientBgPinkRed,
  gradientBgPurplePink,
} from "@/utils/colorConstant";

type Props = {
  bg: BgKey;
  children: ReactNode;
};

export default function SectionFullScreen({ bg, children }: Props) {
  let componentClass = "flex min-h-screen items-center justify-center ";

  if (bg === "purplePink") {
    componentClass += gradientBgPurplePink;
  } else if (bg === "pinkRed") {
    componentClass += gradientBgPinkRed;
  } else if (bg == "gray") {
    componentClass += gradientBgPGray;
  }

  return <div className={componentClass}>{children}</div>;
}
