import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Table,
  ArrowUpRightIc,
  Dialog,
  Button,
  DialogFooter,
} from "@/components/Elements";
import { RootState } from "@/store/reducers";

import {
  useBlockUnblockAccountMutation,
  useGetAccountListMutation,
} from "../api/account";
import { getAddressDetail, mapStringToLocation } from "@/libs/location";
import { ProfileModel } from "../types";
import useToggle from "@/hooks/useToggle";
import { AccountDetail } from "./AccountDetail";
import { themeTextColor } from "@/utils/colorConstant";

const Action = ({ entry }: { entry: ProfileModel }) => {
  const { show: showModal, toggle: toggleModal } = useToggle();
  const { show: showConfirmModal, toggle: toggleConfirmModal } = useToggle();
  const blockTxt = entry.isActive ? "Block" : "Unblock";
  const [blockUnblockAccount] = useBlockUnblockAccountMutation();

  const handleBlockUnblock = () => {
    try {
      blockUnblockAccount({
        userId: entry.userId,
        isActive: !entry.isActive,
      });
    } catch (ex) {
      console.log(`ex`, ex);
    }
    toggleConfirmModal();
  };

  return (
    <>
      <div className="flex justify-between">
        <button onClick={toggleModal}>
          <ArrowUpRightIc className={`w-6 h-6 ${themeTextColor}`} />
        </button>
        <Button size="sm" className="ml-2" onClick={toggleConfirmModal}>
          {blockTxt}
        </Button>
      </div>
      <Dialog isOpen={showModal} onClose={toggleModal} title="Profile Detail">
        <AccountDetail userId={entry.userId} />
      </Dialog>
      <Dialog
        isOpen={showConfirmModal}
        onClose={toggleConfirmModal}
        title={`${blockTxt} Account`}
      >
        You are about to {blockTxt} this account. Please click Yes to proceed.
        <DialogFooter onClose={toggleConfirmModal}>
          <Button size="sm" className="ml-2" onClick={handleBlockUnblock}>
            Yes
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export const AccountList = () => {
  const [getAccountList] = useGetAccountListMutation();
  const allProfileList = useSelector(
    (state: RootState) => state.account.profileList
  );
  const [profileList, setProfileList] = useState<ProfileModel[]>([]);

  useEffect(() => {
    (async () => {
      setProfileList(
        await Promise.all(
          allProfileList.map(async (c) => {
            const location = mapStringToLocation(c.location);
            return {
              ...c,
              address: location
                ? (await getAddressDetail(location))?.address
                : undefined,
            };
          })
        )
      );
    })();
  }, [allProfileList]);

  useEffect(() => {
    getAccountList();
  }, []);

  return (
    <Table
      data={profileList}
      id="userId"
      columns={[
        {
          title: "First Name",
          field: "firstName",
        },
        {
          title: "Last Name",
          field: "lastName",
        },
        {
          title: "Email",
          field: "email",
        },
        {
          title: "Phone",
          field: "mobile",
        },
        {
          title: "Distance Radius (miles)",
          field: "distanceRadius",
        },
        {
          title: "Address",
          field: "address",
        },
        {
          title: "View",
          field: "action",
          Cell: Action,
        },
      ]}
    />
  );
};
