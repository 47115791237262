import { MenuNavBarItem } from "@/types/common";
import { LogoutIc } from "../Elements";

const menuNavBar: MenuNavBarItem[] = [
  {
    isCurrentUser: true,
    menu: [
      {
        icon: LogoutIc,
        label: "Log Out",
        isLogout: true,
        href: "/",
      },
    ],
  },
  {
    icon: LogoutIc,
    label: "Log out",
    isDesktopNoLabel: true,
    isLogout: true,
  },
];

export default menuNavBar;
