import { Middleware, isRejectedWithValue } from "@reduxjs/toolkit";
import { resetStateAction } from "./resetState";

export const unauthenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action: any) => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      dispatch(resetStateAction());
      window.location.assign(window.location.origin as unknown as string);
    }

    return next(action);
  };
