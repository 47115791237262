import * as z from "zod";

import { Button } from "@/components/Elements";
import { Form, InputField } from "@/components/Form";
import { useAuth } from "@/libs/auth";

import { useAuthenticateUserMutation } from "../api/login";

const schema = z.object({
  email: z.string().min(1, "Required"),
  password: z.string().min(1, "Required"),
});

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const [authenticateUser] = useAuthenticateUserMutation();
  const { login } = useAuth();

  const handleSubmit = async (values: LoginValues) => {
    try {
      const data = await authenticateUser(values).unwrap();
      if (data.authToken) {
        login(data);
        onSuccess();
      }
    } catch (e) {
      console.log("Err", e);
    }
  };

  return (
    <div>
      <Form<LoginValues, typeof schema>
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors["email"]}
              registration={register("email")}
            />
            <InputField
              type="password"
              label="Password"
              error={formState.errors["password"]}
              registration={register("password")}
            />
            <div>
              <Button type="submit" className="w-full mt-6">
                Log in
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
