import { CSSProperties, useEffect, useState } from "react";

import { baseURL } from "@/services/api";

type ImageProps = {
  src?: string | null | undefined;
  fileDataAsBase64?: string | null | undefined;
  isApiUrl?: boolean;
  className?: string;
  alt?: string;
  style?: CSSProperties | undefined;
};

export const Image = ({
  src,
  fileDataAsBase64,
  isApiUrl,
  className,
  alt,
  style,
}: ImageProps) => {
  const [imageSrc, setImageSrc] = useState<string>();

  useEffect(() => {
    if (fileDataAsBase64) {
      setImageSrc(`data:image/jpeg;base64,${fileDataAsBase64}`);
    } else if (isApiUrl && src) {
      setImageSrc(`${baseURL}${src}`);
    } else setImageSrc(`/assets/images/${src}`);
  }, [src, isApiUrl]);

  const onError = () => {
    setImageSrc(`/assets/images/profile-pic.jpg`);
  };

  return (
    <img
      src={imageSrc}
      className={className}
      alt={alt}
      style={style}
      onError={onError}
    />
  );
};
