import { api } from "@/services/api";

import { LoginResponse } from "../types";

const loginApi = api.injectEndpoints({
  endpoints: (build) => ({
    authenticateUser: build.mutation<LoginResponse, any>({
      query: (payload) => {
        return {
          url: "api/Account/ValidateUser",
          method: "post",
          data: payload,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useAuthenticateUserMutation } = loginApi;

export default loginApi;
