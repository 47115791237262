import { PropsWithChildren } from "react";

const Action = ({
  children,
  text,
}: PropsWithChildren<{
  text: number | string;
}>) => {
  return (
    <div className="flex items-center">
      {children}
      <div className="text-sm text-[#425460] ml-2">{text?.toString()}</div>
    </div>
  );
};

export default function ActionBtn({
  ...rest
}: PropsWithChildren<{
  text: number | string;
}>) {
  return <Action {...rest} />;
}
