import { useAuth } from "@/libs/auth";
import { RouteConstant } from "@/routes/routeConstant";
import { baseURL } from "@/services/api";

import { Navigate } from "react-router-dom";

import "../scss/index.scss";

export const Landing = () => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to={{ pathname: RouteConstant.Account }} />;
  }

  return (
    <div className="landingPage">
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="banner-content">
                <div className="logo">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                    alt=""
                  />
                  <span className="color-purple">Proxi</span>mate
                </div>
                <h1>Post</h1>
                <p>
                  You can create and publish post for specific location. You can
                  also see post available near to your location.
                </p>
                <div className="banner-btn-grp">
                  <button className="btn btn-banner play-store">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/playstore.png`}
                      alt=""
                    />
                  </button>
                  <button className="btn btn-banner app-store">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`}
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <img
                className="banner-img"
                alt=""
                src={`${process.env.PUBLIC_URL}/assets/images/phone2.png`}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="ab-us sec-pad">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 text-center">
              <img
                className="banner-img"
                alt=""
                src={`${process.env.PUBLIC_URL}/assets/images/phone3.png`}
              />
            </div>
            <div className="col-sm-6">
              <div className="ab-content">
                <div className="numb">01</div>
                <h3 className="sm-title">Ready to get started?</h3>
                <ul className="ab-list">
                  <li>Can sign up with google, facebook or instagram.</li>
                  <li>
                    Can register for new account with some limited information.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 text-center">
              <img
                className="banner-img"
                alt=""
                src={`${process.env.PUBLIC_URL}/assets/images/phone4.png`}
              />
            </div>
            <div className="col-sm-6">
              <div className="ab-content">
                <div className="numb">02</div>
                <h3 className="sm-title">Chat</h3>
                <ul className="ab-list">
                  <li>Chat with users who commented or likes on your post.</li>
                  <li>Chat with users available near to your location.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="logo footer-logo">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                  alt=""
                />
                <span className="color-purple">Proxi</span>mate
              </div>
              <p>A108 Adam Street</p>
              <p>New York, NY 535022</p>
              <p className="mt-3">
                <strong>Phone:</strong> <span>+1 5589 55488 55</span>
              </p>
              <p>
                <strong>Email:</strong> <span>info@example.com</span>
              </p>
            </div>
            <div className="col-sm-4">
              <div className="flinks">
                <h3>Useful Links</h3>
                <a href="#">Home</a>
                <a href="#">About us</a>
                <a href="#">Services</a>
                <a href={`${baseURL}templates/termsandconditions.html`}>
                  Terms of service
                </a>
                <a href={`${baseURL}templates/privacypolicy.html`}>
                  Privacy policy
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="flinks">
                <h3>Get the app</h3>
                <button className="btn btn-footer play-store">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/playstore.png`}
                    alt=""
                  />
                </button>
                <button className="btn btn-footer app-store">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="copyright">
            © 2024 <span className="color-green">Proximate</span>, All rights
            reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};
