import { ContentLayout } from "@/components/Layout";

export const DeleteAccount = () => {
  return (
    <ContentLayout title="Delete Account">
      <div className="bg-white flex mb-6 rounded-2xl flex-col dark:bg-slate-900/70">
        <div className="flex-1"></div>
      </div>
    </ContentLayout>
  );
};
