import { RootState } from "@/store/reducers";
import { useSelector } from "react-redux";
import { PostCommentModel } from "../../types";
import { ImageList } from "@/components/Elements";
import { useEffect } from "react";
import { useGetCommentsMutation } from "../../api/account";
import { ProfileImg } from "@/components/Common";

function Comment({ commentDetail }: { commentDetail: PostCommentModel }) {
  const {
    userName: createdBy,
    userImageUrl: createdByImgUrl,
    createdOn,
    comment,
    files,
    postDescription,
    postFileList,
  } = commentDetail;

  return (
    <>
      <div>
        <div className="flex items-center">
          <div className="w-[50px] h-[50px] rounded">
            <ProfileImg src={createdByImgUrl} />
          </div>
          <div className="font-bold">{createdBy}</div>
        </div>
        <div className="my-2">
          <div className="mb-5">{postDescription}</div>
          <ImageList files={postFileList} />
        </div>
        <div className={`mb-5 flex`}>
          <div className={`w-[80%] p-3 bg-[#e6e6e6] text-black`}>
            <div className="text-sm grow shrink">{comment}</div>
            <ImageList files={files} />
            <div className="ml-5 flex justify-end">
              <div className="sm">{createdOn}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const Comments = ({ userId }: { userId: number }) => {
  const commentList = useSelector(
    (state: RootState) => state.postComment.commentList
  );
  const [getComments] = useGetCommentsMutation();

  useEffect(() => {
    getComments(userId);
  }, [userId]);

  return (
    <>
      {commentList.map((item) => (
        <Comment commentDetail={item} />
      ))}
    </>
  );
};
