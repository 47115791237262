import { useEffect, useState } from "react";

type Tab = {
  id: string;
  label: string;
  Content({ id }: { id: string }): React.ReactElement;
};

export const TabPanel = ({
  tabs,
  selectedTab,
  handleSelect,
}: {
  tabs: Tab[];
  selectedTab?: Tab;
  handleSelect: (tab: Tab) => void;
}) => {
  return (
    <ul
      className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
      role="tablist"
    >
      {tabs.map((tab) => (
        <li
          className={
            "-mb-px last:mr-0 flex-auto text-center border-b-4 " +
            (tab.id === selectedTab?.id ? "border-b-red-400" : "")
          }
          key={tab.id}
        >
          <a
            className={
              "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
            }
            onClick={(e) => {
              e.preventDefault();
              handleSelect(tab);
            }}
            data-toggle="tab"
            href={`#${tab.id}`}
            role="tablist"
          >
            {tab.label}
          </a>
        </li>
      ))}
    </ul>
  );
};

export const Tabs = ({ tabs }: { tabs: Tab[] }) => {
  const [selectedTab, setSelectedTab] = useState<Tab>();

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      setSelectedTab(tabs[0]);
    }
  }, [tabs]);

  return (
    <div className="flex flex-wrap">
      <div className="w-full">
        <TabPanel
          tabs={tabs}
          selectedTab={selectedTab}
          handleSelect={setSelectedTab}
        />
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="px-4 py-5 flex-auto">
            <div className="tab-content tab-space">
              {selectedTab && (
                <div id={selectedTab.id}>
                  <selectedTab.Content id={selectedTab.id} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
