import { ContentLayout } from "@/components/Layout";
import { Authorization, ROLES } from "@/libs/authorization";

import { AccountList } from "../components";

export const Accounts = () => {
  return (
    <ContentLayout title="Accounts">
      <div className="bg-white flex mb-6 rounded-2xl flex-col dark:bg-slate-900/70">
        <div className="flex-1">
          <Authorization
            forbiddenFallback={<div>Only Admin can view this.</div>}
            allowedRoles={[ROLES.ADMIN]}
          >
            <AccountList />
          </Authorization>
        </div>
      </div>
    </ContentLayout>
  );
};
