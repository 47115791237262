import { z } from "zod";

import { RouteConstant } from "@/routes/routeConstant";

import { Button, Link } from "../Elements";
import { Form, InputField } from "../Form";

const schema = z.object({
  email: z.string(),
});

type SignUpValues = z.infer<typeof schema>;

const LoginFooter = () => {
  const handleSubmit = async (values: SignUpValues) => {
    try {
      console.log("values", values);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url(/assets/images/footerbg.jpg)",
        backgroundSize: "cover",
      }}
      className="mb-10 p-10"
    >
      <div className="flex justify-between">
        <h1 className="text-white text-5xl font-extrabold">
          Start your journey as a retailer
        </h1>
        <Form<SignUpValues, typeof schema>
          onSubmit={async (values) => {
            handleSubmit(values);
          }}
          schema={schema}
        >
          {({ register, formState }) => (
            <div>
              <div className="grid grid-cols-2 bg-white p-4">
                <InputField
                  type="text"
                  placeholder="Email Address"
                  error={formState.errors["email"]}
                  registration={register("email")}
                />
                <Button
                  type="submit"
                  size="sm"
                  className="float-right bg-orange-500 py-3"
                >
                  Get Started
                </Button>
              </div>
              <div className="text-sm text-white mt-2">
                Already a Agent?{" "}
                <Link className="text-orange-500" to={RouteConstant.Login}>
                  Sign in
                </Link>
              </div>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default LoginFooter;
