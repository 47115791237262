import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Spinner } from "@/components/Elements";
import { Layout, MainLayout } from "@/components/Layout";
import { lazyImport } from "@/utils/lazyImport";

import { RouteConstant } from "./routeConstant";

const { Accounts } = lazyImport(() => import("@/features/profile"), "Accounts");
const { DeleteAccount } = lazyImport(
  () => import("@/features/profile"),
  "DeleteAccount"
);

const App = () => {
  return (
    <Layout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </Layout>
  );
};

export const protectedRoutes = [
  {
    path: "/app",
    element: <App />,
    children: [
      { path: RouteConstant.Account, element: <Accounts /> },
      { path: RouteConstant.DeleteUser, element: <DeleteAccount /> },
    ],
  },
];
