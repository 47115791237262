import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import axios, { AxiosError } from "axios";

import { showProgress, hideProgress, setResponse } from "@/store/common";
import { RootState } from "@/store/reducers";

export const baseURL = "https://proximat8.com/api/";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const axiosBaseQuery =
  (): BaseQueryFn<any> =>
  async (requestOpts, { getState, dispatch }) => {
    try {
      dispatch(showProgress());
      const token = (getState() as RootState).login.authToken;
      const response = await axiosInstance({
        ...requestOpts,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response", response);
      const { result, message, success } = response?.data ?? {};
      dispatch(setResponse({ message, status: response?.status, success }));
      dispatch(hideProgress());
      return {
        data: result,
      };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      const { message, success }: any = error.response?.data ?? {};
      dispatch(hideProgress());
      dispatch(
        setResponse({
          message: message,
          status: error?.status,
          success,
        })
      );
      return { error: { status: error.response?.status } };
    }
  };

export const api = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
});
