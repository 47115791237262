import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import loginApi from "../api/login";
import { LoginResponse } from "../types";

const initialState = {
  isLoggedIn: false,
} as LoginState;

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    onSignOut: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(loginApi.endpoints.authenticateUser.matchFulfilled),
      (state, { payload }: { payload: LoginResponse }) => {
        return { ...payload, isLoggedIn: true };
      }
    );
  },
});

export const { onSignOut } = slice.actions;

export const login = slice.reducer;

export interface LoginState extends LoginResponse {
  isLoggedIn: boolean;
}
