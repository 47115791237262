import { Tabs } from "@/components/Elements";
import { Posts } from "./post";
import { Comments } from "./comments";
import { RecipientList } from "./messages";

const Tab = {
  Post: "Post",
  Comments: "Comments",
  Messages: "Messages",
};

export const AccountDetail = ({ userId }: { userId: number }) => {
  return (
    <div className="w-[36vw]">
      <Tabs
        tabs={[
          {
            id: Tab.Post,
            label: Tab.Post,
            Content: () => <Posts userId={userId} />,
          },
          {
            id: Tab.Comments,
            label: Tab.Comments,
            Content: () => <Comments userId={userId} />,
          },
          {
            id: Tab.Messages,
            label: Tab.Messages,
            Content: () => <RecipientList userId={userId} />,
          },
        ]}
      />
    </div>
  );
};
