import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "progress",
  initialState: { isLoading: false } as ProgressState,
  reducers: {
    showProgress: (state) => {
      state.isLoading = true;
    },
    hideProgress: (state) => {
      state.isLoading = false;
    },
  },
});

export const { showProgress, hideProgress } = slice.actions;

export default slice.reducer;

export type ProgressState = {
  isLoading: boolean;
};
