import { BackIc, Image, ImageList } from "@/components/Elements";
import { MessageModel } from "../../types";
import { useSelector } from "react-redux";
import { RootState } from "@/store/reducers";
import { useGetMessagesMutation } from "../../api/account";
import { useEffect } from "react";
import { selectRecipient } from "../../reducers";
import { useDispatch } from "react-redux";
import { ProfileImg } from "@/components/Common";

export function Message({
  messageDetail,
  userId,
}: {
  messageDetail: MessageModel;
  userId: number;
}) {
  const { sentOn, message, fileList: files, sentById } = messageDetail;

  return (
    <>
      <div className={`mb-5 flex ${sentById == userId ? "justify-end" : ""}`}>
        <div
          className={`w-[80%] p-3 ${
            sentById == userId
              ? "bg-sky-100 text-black"
              : "bg-[#e6e6e6] text-black"
          }`}
        >
          <div className="text-sm grow shrink">{message}</div>
          <ImageList files={files} />
          <div className="ml-5 flex justify-end">
            <div className="sm">{sentOn}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export const MessageList = ({ userId }: { userId: number }) => {
  const messages = useSelector((state: RootState) => state.chat.messages);
  const recipientId = useSelector(
    (state: RootState) => state.chat.selectedRecipient?.userId
  );
  const { userImageUrl, fullName } =
    useSelector((state: RootState) => state.chat.selectedRecipient) ?? {};
  const [getMessages] = useGetMessagesMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (recipientId) {
      getMessages({
        recipientId,
        pageNo: 1,
        pageSize: 10,
        userId,
      });
    }
  }, [recipientId, userId]);

  const handleBack = () => {
    dispatch(selectRecipient());
  };

  return (
    <div>
      <div className="flex p-1 items-center">
        <button onClick={handleBack}>
          <BackIc className="h-7 w-7" />
        </button>
        <ProfileImg src={userImageUrl} />
        <div className="ml-5">
          <div className="text-sm font-extrabold">{fullName}</div>
        </div>
      </div>
      <div className="p-1">
        {messages.map((item) => (
          <Message key={item.messageId} messageDetail={item} userId={userId} />
        ))}
      </div>
    </div>
  );
};
