import { ToastContainer } from "react-toastify";

import { AppProvider } from "@/providers/App";
import { AppRoutes } from "@/routes";

import { APIResponse, Loading } from "./components/Common";

function App() {
  return (
    <AppProvider>
      <AppRoutes />
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <APIResponse />
      <Loading />
    </AppProvider>
  );
}

export default App;
