import { ProfileImg } from "@/components/Common";
import { Image } from "@/components/Elements";

export type PostHeaderProps = {
  createdByImgUrl: string;
  createdBy: string;
  createdOn?: string;
};

export const PostHeader = ({
  createdByImgUrl,
  createdBy,
  createdOn,
}: PostHeaderProps) => {
  return (
    <>
      <ProfileImg src={createdByImgUrl} />
      <div className="ml-5">
        <div className="text-sm font-bold">{createdBy}</div>
        <div className="text-xs">{createdOn}</div>
      </div>
    </>
  );
};
