import * as React from "react";

import { Image, Link } from "@/components/Elements";
import { SectionFullScreen } from "@/components/Section";
import { CardBox } from "@/components/Card";

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="bg-gray-50 dark:bg-slate-800 dark:text-slate-100">
      <SectionFullScreen bg="gray">
        <CardBox className="w-11/12 md:w-7/12 lg:w-6/12 xl:w-4/12 shadow-2xl">
          <div className="mb-10">
            <div className="flex justify-center">
              <Image alt="" className="h-14 w-14" src="logo.png" />
            </div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Login to your account
            </h2>
          </div>
          {children}
        </CardBox>
      </SectionFullScreen>
    </div>
  );
};
