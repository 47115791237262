import { useSelector } from "react-redux";

import { RootState } from "@/store/reducers";

import { Spinner } from "../Elements";

export default function Loading() {
  const { isLoading } = useSelector((state: RootState) => state.progress);

  return (
    <>
      {isLoading && (
        <div className="absolute top-1/2 left-1/2">
          <Spinner size="lg" />
        </div>
      )}
    </>
  );
}
