import { createSlice } from "@reduxjs/toolkit";
import accountApi from "../api/account";
import { PostCommentModel } from "../types";

const slice = createSlice({
  name: "post",
  initialState: {
    commentList: [],
  } as PostCommentState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      accountApi.endpoints.getComments.matchPending,
      (state) => {
        return {
          ...state,
          commentList: [],
        };
      }
    );
    builder.addMatcher(
      accountApi.endpoints.getComments.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          commentList: payload,
        };
      }
    );
  },
});

export const postComment = slice.reducer;

export interface PostCommentState {
  commentList: PostCommentModel[];
}
