import * as React from "react";

import { Head } from "../Head";
import { containerMaxW } from "@/utils/config";

type ContentLayoutProps = {
  children: React.ReactNode;
  title?: string;
};

export const ContentLayout = ({ children, title }: ContentLayoutProps) => {
  return (
    <>
      <Head title={title} />
      <section className={`p-6 ${containerMaxW}`}>
        <section className={` mb-6 flex items-center justify-between`}>
          <h1 className="leading-tight text-3xl">{title}</h1>
        </section>
        {children}
      </section>
    </>
  );
};
