import { ReactNode } from "react";
import { NavBar } from "@/components/NavBar";
import NavBarItemPlain from "@/components/NavBar/Item/Plain";
import menuNavBar from "./menuNavBar";
import { ColorConstant, themeTextColor } from "@/utils/colorConstant";

type Props = {
  children: ReactNode;
};

export const Layout = ({ children }: Props) => {
  return (
    <div className={`overflow-hidden lg:overflow-visible`}>
      <div
        className={`pt-14 min-h-screen w-screen transition-position lg:w-auto bg-gray-50 dark:bg-slate-800 dark:text-slate-100`}
      >
        <NavBar menu={menuNavBar} className="">
          <NavBarItemPlain display="hidden lg:flex xl:flex">
            <h1 className={`leading-relaxed text-3xl`}>
              <span className={` ${themeTextColor}`}>PROXI</span>
              <span className="text-[#f77b4f]">MATE</span>
            </h1>
          </NavBarItemPlain>
        </NavBar>
        {children}
      </div>
    </div>
  );
};
