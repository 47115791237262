import { Navigate, useRoutes } from "react-router-dom";

import { Landing } from "@/features/misc";
import { useAuth } from "@/libs/auth";

import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import { RouteConstant } from "./routeConstant";

export const AppRoutes = () => {
  const { isLoggedIn } = useAuth();

  const commonRoutes = [
    { path: RouteConstant.Landing, element: <Landing /> },
    { path: "*", element: <Navigate to={RouteConstant.Landing} /> },
  ];

  const routes = isLoggedIn ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
