import { api } from "@/services/api";

import {
  MessageModel,
  PostCommentModel,
  PostModel,
  ProfileModel,
  RecipientModel,
} from "../types";

const accountApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccountList: build.mutation<ProfileModel[], void>({
      query: (payload) => {
        return {
          url: "api/Profile/GetProfileList",
          method: "get",
          data: payload,
        };
      },
    }),
    blockUnblockAccount: build.mutation<any, any>({
      query: (payload) => {
        return {
          url: "api/Profile/BlockUnblockUser",
          method: "post",
          data: payload,
        };
      },
    }),
    getPosts: build.mutation<PostModel[], any>({
      query: (payload) => {
        return {
          url: "api/Post/GetPosts",
          method: "post",
          data: payload,
        };
      },
    }),
    updatePostStatus: build.mutation<PostModel[], any>({
      query: (payload) => {
        return {
          url: "api/Post/UpdatePostStatus",
          method: "post",
          data: payload,
        };
      },
    }),
    getComments: build.mutation<PostCommentModel[], any>({
      query: (payload) => {
        return {
          url: "api/Comment/GetUserComments",
          method: "post",
          data: payload,
        };
      },
    }),
    getRecipients: build.mutation<RecipientModel[], any>({
      query: (payload) => {
        return {
          url: "api/Chat/GetUserRecipients",
          method: "post",
          data: payload,
        };
      },
    }),
    getMessages: build.mutation<MessageModel[], any>({
      query: (payload) => {
        return {
          url: "api/Chat/GetUserMessages",
          method: "post",
          data: payload,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAccountListMutation,
  useBlockUnblockAccountMutation,
  useGetPostsMutation,
  useUpdatePostStatusMutation,
  useGetCommentsMutation,
  useGetRecipientsMutation,
  useGetMessagesMutation,
} = accountApi;

export default accountApi;
