import { useSelector, useDispatch } from "react-redux";

import { LoginResponse, onSignOut } from "@/features/auth";
import { RootState } from "@/store/reducers";
import storage from "@/utils/storage";

async function handleUserResponse(data: LoginResponse) {
  const { authToken } = data;
  storage.setToken(authToken);
}

export async function logoutFn() {
  storage.clearToken();
  window.location.assign(window.location.origin as unknown as string);
}

export const useAuth = () => {
  const { isLoggedIn, userDetail } = useSelector(
    (state: RootState) => state.login
  );
  const dispatch = useDispatch();

  const login = (data: LoginResponse) => {
    handleUserResponse(data);
  };

  const logout = () => {
    logoutFn();
    dispatch(onSignOut());
  };

  return {
    isLoggedIn,
    role: userDetail?.roles?.[0],
    login,
    logout,
  };
};
