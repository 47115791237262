import { Image } from "@/components/Elements";
import { RecipientModel } from "../../types";
import { ColorConstant } from "@/utils/colorConstant";
import { useDispatch } from "react-redux";
import { selectRecipient } from "../../reducers";
import { ProfileImg } from "@/components/Common";

const Recipient = ({ recipient }: { recipient: RecipientModel }) => {
  const {
    fullName,
    userImageUrl,
    lastMessage,
    lastMessageSentOn,
    unReadCount,
  } = recipient;
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(selectRecipient(recipient));
  };

  return (
    <div className="h-[50px] mb-10">
      <div className="flex">
        <ProfileImg src={userImageUrl} />
        <button className="ml-3 w-full" onClick={onClick}>
          <div className="flex justify-between">
            <div className="text-sm">{fullName}</div>
            <div className="text-xs">{lastMessageSentOn}</div>
          </div>
          <div className="flex justify-between">
            <div className="text-sm">{lastMessage}</div>
            {unReadCount > 0 && (
              <div
                className={`w-4 h-4 rounded-full items-center justify-center border flex bg-[${ColorConstant.themeColor}]`}
              >
                <div>{unReadCount}</div>
              </div>
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default Recipient;
