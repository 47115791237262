import clsx from "clsx";
import { UseFormRegisterReturn } from "react-hook-form";

import { FieldWrapperPassThroughProps } from "@/types/common";

import { FieldWrapper } from "./FieldWrapper";

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: "text" | "email" | "password";
  className?: string;
  placeholder?: string;
  name?: string;
  value?: string;
  onChange?: (name: string, value: string) => void;
  registration?: Partial<UseFormRegisterReturn>;
};

export const InputField = (props: InputFieldProps) => {
  const {
    type = "text",
    label,
    name,
    value,
    onChange,
    className,
    placeholder,
    registration,
    error,
  } = props;

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.name, e.target.value);
  };

  return (
    <FieldWrapper label={label} error={error}>
      <input
        type={type}
        className={clsx(
          "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
          className
        )}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onFieldChange}
        {...registration}
      />
    </FieldWrapper>
  );
};
