import { LatLngModel, MapAddressModel } from "@/types/common";
import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyCqBqYD2QbwpKp8j6tEaEMQfVQJUD8eDlg",
  version: "weekly",
});

export const getAddressDetail = async (
  location: LatLngModel
): Promise<MapAddressModel> => {
  loader.load().then(async () => {
    const geocoder = new google.maps.Geocoder();
    const { results } = await geocoder.geocode({
      location: { lat: 51.447338, lng: -0.40905 },
    });
    if (results[0]) {
      const adrs_comp = results[0].formatted_address;
      const value = adrs_comp.split(",");
      const addressDetail = {
        city: value[value.length - 3],
        streetName: value[value.length - 4],
        county: value[value.length - 2],
        postCode: results[0].address_components?.find(
          (addr) => addr.types[0] === "postal_code"
        )?.short_name,
        country: value[value.length - 1],
      };
      return {
        ...addressDetail,
        address: `${addressDetail.streetName}, ${addressDetail.city}, ${addressDetail.county}, ${addressDetail.country}, ${addressDetail.postCode}`,
      };
    }
  });

  return {};
};

export function mapLocationToString(location?: LatLngModel): string {
  return location ? `${location.lat},${location.lng}` : "";
}

export function mapStringToLocation(location?: string): LatLngModel | null {
  if (location) {
    const locationArr = location.split(",");
    if (locationArr.length >= 2) {
      return {
        lat: parseFloat(locationArr[0]),
        lng: parseFloat(locationArr[1]),
      };
    }
  }

  return null;
}
