import * as z from "zod";

import { FileModel } from "@/types/common";
import { PostModel } from "../../types";
import { PostHeader } from "./PostHeader";
import {
  CommentIc,
  Image,
  MapPinIc,
  ThumbIc,
  Video,
} from "@/components/Elements";
import ActionBtn from "./ActionBtn";
import { useEffect, useState } from "react";
import { getAddressDetail, mapStringToLocation } from "@/libs/location";
import { themeTextColor } from "@/utils/colorConstant";
import { Checkbox, Form } from "@/components/Form";
import { useUpdatePostStatusMutation } from "../../api/account";
import { PostStatusOption } from "@/utils/listOption";

const schema = z.object({
  isActive: z.boolean(),
});

type FormValues = {
  isActive: boolean;
};

const PostStatus = ({ postDetail }: { postDetail: PostModel }) => {
  const [updatePostStatus] = useUpdatePostStatusMutation();

  const handleSubmit = async ({ isActive }: FormValues) => {
    try {
      const { postId, userId } = postDetail;
      await updatePostStatus({
        postId,
        userId,
        status: isActive ? PostStatusOption.Active : PostStatusOption.Draft,
      }).unwrap();
    } catch (e) {}
  };

  const { postStatus } = postDetail;

  return (
    <Form<FormValues, typeof schema>
      onSubmit={async (values) => {
        handleSubmit(values);
      }}
      schema={schema}
      options={{
        defaultValues: { isActive: postStatus == PostStatusOption.Active },
      }}
    >
      {({ register }) => (
        <Checkbox
          label="Active"
          registration={{
            ...register("isActive"),
            onChange: (event) => handleSubmit({ isActive: event.target.value }),
          }}
        />
      )}
    </Form>
  );
};

export const Post = ({ postDetail }: { postDetail: PostModel }) => {
  const {
    author: createdBy,
    authorImageUrl: createdByImgUrl,
    createdOn: createdOn,
    description,
    files,
    likeCount: noOfLikes,
    commentCount,
    postLocation,
  } = postDetail;
  const [postCode, setPostCode] = useState<string>("");

  useEffect(() => {
    if (postLocation) {
      (async () => {
        const location = mapStringToLocation(postLocation);
        if (location) {
          //   const { postCode } = await getAddressDetail(location);
          //   setPostCode(postCode ?? "");
        }
      })();
    }
  }, [postLocation]);

  return (
    <div className="mb-10">
      <div className="flex">
        <PostHeader
          createdByImgUrl={createdByImgUrl}
          createdBy={createdBy}
          createdOn={createdOn}
        />
      </div>
      <div className="mt-6 mb-5 flex justify-between">
        <div>{description}</div>
        <PostStatus postDetail={postDetail} />
      </div>
      {files && files.length > 0 && (
        <div className="mb-4">
          {files.map((f: FileModel, index: number) => (
            <div key={`f${index}`} className="h-44">
              {f.fileUrl.includes(".mp4") ? (
                <Video apiSrc={f.fileUrl} className="w-full h-full" />
              ) : (
                <Image src={f.fileUrl} className="w-full h-full" isApiUrl />
              )}
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-between py-5 border-b">
        <ActionBtn text={noOfLikes}>
          <ThumbIc className={`w-6 h-6 ${themeTextColor}`} />
        </ActionBtn>
        <ActionBtn text={postCode}>
          <MapPinIc className={`w-6 h-6  ${themeTextColor}`} />
        </ActionBtn>
        <ActionBtn text={commentCount}>
          <CommentIc className={`w-6 h-6  ${themeTextColor}`} />
        </ActionBtn>
      </div>
    </div>
  );
};
