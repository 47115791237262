import { baseURL } from "@/services/api";

export const Video = ({
  src,
  className,
  apiSrc,
}: {
  src?: string;
  className: string;
  apiSrc?: string;
}) => {
  return (
    <video src={apiSrc ? `${baseURL}${apiSrc}` : src} className={className} />
  );
};
