import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useToast from "@/hooks/useToast";
import { setResponse } from "@/store/common";
import { RootState } from "@/store/reducers";

export default function APIResponse() {
  const dispatch = useDispatch();
  const { success, message } = useSelector(
    (state: RootState) => state.response
  );
  const { showError, showSuccess } = useToast(onClose);

  useEffect(() => {
    if (message) {
      if (success) showSuccess(message);
      else showError(message);
    }
  }, [message]);

  function onClose() {
    dispatch(setResponse({ message: "" }));
  }

  return <></>;
}
