import NavBarItem from "./Item";
import { MenuNavBarItem } from "@/types/common";

type Props = {
  menu: MenuNavBarItem[];
};

export default function NavBarMenuList({ menu }: Props) {
  return (
    <>
      {menu.map((item, index) => (
        <NavBarItem key={index} item={item} />
      ))}
    </>
  );
}
