import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/reducers";
import { MenuNavBarItem } from "@/types/common";
import { ProfileImg } from "@/components/Common";
import { ChevronDownIc, ChevronUpIc } from "@/components/Elements";
import NavBarMenuList from "../MenuList";
import { useAuth } from "@/libs/auth";
import { Link } from "react-router-dom";

type Props = {
  item: MenuNavBarItem;
};

export default function NavBarItem({ item }: Props) {
  const { name: userName, profileImageUrl } = useSelector(
    (state: RootState) => state.login.userDetail
  );
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const { logout } = useAuth();

  const componentClass = [
    "block lg:flex items-center relative cursor-pointer",
    isDropdownActive
      ? `navbar-item-label-active dark:text-slate-400`
      : `navbar-item-label dark:text-white dark:hover:text-slate-400`,
    item.menu ? "lg:py-2 lg:px-3" : "py-2 px-3",
    item.isDesktopNoLabel ? "lg:w-16 lg:justify-center" : "",
  ].join(" ");

  const itemLabel = item.isCurrentUser ? userName : item.label;

  const handleMenuClick = () => {
    if (item.menu) {
      setIsDropdownActive(!isDropdownActive);
    }
    if (item.isLogout) {
      logout();
    }
  };

  const NavBarItemComponentContents = (
    <>
      <div
        className={`flex items-center ${
          item.menu
            ? "bg-gray-100 dark:bg-slate-800 lg:bg-transparent lg:dark:bg-transparent p-3 lg:p-0"
            : ""
        }`}
        onClick={handleMenuClick}
      >
        {item.isCurrentUser && <ProfileImg src={profileImageUrl} />}
        {item.icon && <item.icon />}
        <span
          className={`px-2 transition-colors ${
            item.isDesktopNoLabel && item.icon ? "lg:hidden" : ""
          }`}
        >
          {itemLabel}
        </span>
        {item.menu && (
          <>
            {isDropdownActive ? (
              <ChevronUpIc className="h-5 w-5" />
            ) : (
              <ChevronDownIc className="h-5 w-5" />
            )}
          </>
        )}
      </div>
      {item.menu && (
        <div
          className={`${
            !isDropdownActive ? "lg:hidden" : ""
          } text-sm border-b border-gray-100 lg:border lg:bg-white lg:absolute lg:top-full lg:left-0 lg:min-w-full lg:z-20 lg:rounded-lg lg:shadow-lg lg:dark:bg-slate-800 dark:border-slate-700`}
        >
          <NavBarMenuList menu={item.menu} />
        </div>
      )}
    </>
  );

  if (item.href) {
    return (
      <Link to={item.href} target={item.target} className={componentClass}>
        {NavBarItemComponentContents}
      </Link>
    );
  }

  return <div className={componentClass}>{NavBarItemComponentContents}</div>;
}
