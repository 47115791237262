import clsx from "clsx";
import { UseFormRegisterReturn } from "react-hook-form";

import { FieldWrapperPassThroughProps } from "@/types/common";

import { FieldWrapper } from "./FieldWrapper";

type InputFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const Checkbox = (props: InputFieldProps) => {
  const { label, className, registration, error } = props;
  return (
    <FieldWrapper error={error} className="flex items-center">
      <input
        type="checkbox"
        className={clsx(
          "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600",
          className
        )}
        {...registration}
      />
      <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
        {label}
      </label>
    </FieldWrapper>
  );
};
