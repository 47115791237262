import { useEffect } from "react";
import { useGetPostsMutation } from "../../api/account";
import { useSelector } from "react-redux";
import { RootState } from "@/store/reducers";
import { Post } from "./Post";

export const Posts = ({ userId }: { userId: number }) => {
  const [getPosts] = useGetPostsMutation();
  const posts = useSelector((state: RootState) => state.post.posts);

  useEffect(() => {
    getPosts({ getUserPost: true, userId });
  }, [userId]);

  return (
    <div>
      {posts.map((post) => (
        <Post postDetail={post} key={post.postId} />
      ))}
    </div>
  );
};
