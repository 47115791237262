import * as React from "react";

import { useAuth } from "./auth";

export enum ROLES {
  ADMIN = "Admin",
}

export const useAuthorization = () => {
  const { isLoggedIn, role } = useAuth();

  if (!isLoggedIn) {
    throw Error("User does not exist!");
  }

  const checkAccess = React.useCallback(
    ({ allowedRoles }: { allowedRoles: ROLES[] }) => {
      if (allowedRoles && allowedRoles.length > 0) {
        return allowedRoles?.includes(role as ROLES);
      }

      return true;
    },
    [role]
  );

  return { checkAccess, role };
};

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
} & (
  | {
      allowedRoles: ROLES[];
      policyCheck?: never;
    }
  | {
      allowedRoles?: never;
      policyCheck: boolean;
    }
);

export const Authorization = ({
  policyCheck,
  allowedRoles,
  forbiddenFallback = null,
  children,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  if (typeof policyCheck !== "undefined") {
    canAccess = policyCheck;
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};
