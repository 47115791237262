export const ColorConstant = {
  themeColor: "#7a74c3",
};

export const gradientBgBase = "bg-gradient-to-tr";
export const gradientBgPurplePink = `${gradientBgBase} from-purple-400 via-pink-500 to-red-500`;
export const gradientBgDark = `${gradientBgBase} from-slate-700 via-slate-900 to-slate-800`;
export const gradientBgPinkRed = `${gradientBgBase} from-pink-400 via-red-500 to-yellow-500`;
export const gradientBgPGray = `${gradientBgBase} from-gray-50`;
export const themeTextColor = `text-[#7a74c3]`;
